/*eslint-disable */

import HTTP from './HTTP';

const URL_BASE = 'setting/';

export async function set(data, group) {
    return await HTTP.post(`${URL_BASE}set${group ? '/'+group : ''}`, data);
}

export async function get (group) {
    return await HTTP.get(`${URL_BASE}get${group ? '/'+group : ''}`);
}

export async function status() {
    return await HTTP.get('/status')
}
