import axios from 'axios';
import router from '@/router';
import store from '../store';

const HTTP = axios.create();

HTTP.interceptors.request.use(
  (request) => {
    request.headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + store.getters.token,
    };
    if (request.url.indexOf('http') === -1) {
      request.url = process.env.VUE_APP_BASE_URL_API + request.url;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const errorsName = [
  'TokenExpiredError',
  'NoUserWithToken',
  'NotAdmin',
  'TokenMismatched',
];

HTTP.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error !== undefined && error.response !== undefined
      && error.response.status === 401 && errorsName.includes(error.response.data.name)) {
    store.dispatch('reset');
    router.go('/login');
  }
  throw error;
});

export default HTTP;
