import Vue from 'vue';
import Buefy from 'buefy';
import VueIntercom from 'vue-intercom';
import VueMeta from 'vue-meta';
import App from './App';
import router from './router';
import store from './store';
import './assets/scss/app.scss';

Vue.use(Buefy);
Vue.use(VueIntercom, {});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    showError(error) {
      let message = error.message;
      if (error.response) {
        message = error.response.data.message || error.response.data.messages.join('<br>');
      }
      this.$buefy.toast.open({
        duration: 5000,
        message: message,
        position: 'is-bottom',
        type: 'is-danger',
      });
    },
    url: (path) => `${process.env.VUE_APP_URL}${path}`,
  },
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
