import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { status } from '@/api/system';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userId: null,
    user: null,
    email: '',
    auth: null,
    nav: false,
    appReady: false,
    settings: {},
    status: {},
    businessOpen: false,
  },
  mutations: {
    SET_EMAIL: (state, val) => {
      state.email = val;
    },
    SET_USER: (state, val) => {
      state.user = val;
    },
    SET_USER_ID: (state, val) => {
      state.userId = val;
    },
    RESET: (state) => {
      state.userId = null;
      state.user = null;
      state.email = '';
    },
    SET_AUTH: (state, val) => {
      state.auth = val;
    },
    SET_NAV: (state, val) => {
      state.nav = val;
    },
    SET_APP_STATE: (state, val) => {
      state.appReady = val;
    },
    SETTINGS: (state, setting) => {
      state.settings = { ...setting };
    },
    STATUS: (state, status) => {
      state.status = { ...status };
    },
    SET_BUSINESS_OPENING: (state, val) => {
      state.businessOpen = val;
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    getStatus({ commit }) {
      return new Promise((resolve, reject) => {
        status().then(response => {
          commit('STATUS', response.data);
          resolve();
        }).catch(e => reject(e));
      });
    },
  },
  getters: {
    user: (state) => state.user,
    userId: (state) => state.userId,
    email: (state) => state.email,
    token: (state) => {
      if (state.user) return state.user.token;
      return '';
    },
    profil: (state) => {
      if (state.user) return state.user.profil;
      return '';
    },
    userName: (state) => {
      if (state.user) return `${state.user.firstName} ${state.user.lastName}`;
      return '';
    },
    auth: state => state.auth,
    nav: state => state.nav,
    appReady: state => state.appReady,
    settings: state => state.settings,
    status: state => state.status,
    businessOpen: state => state.businessOpen,
  },
  modules: {
  },
  plugins: [createPersistedState({
    paths: ['userId', 'email', 'user'],
    key: 'Telemedecine',
  })],
});
