export const ADMIN = 'ADMIN';
export const DOCTOR = 'DOCTOR';
export const PATIENT = 'PATIENT';
export const PROFILS = [
  {
    value: ADMIN,
    label: 'Admin',
  },
  {
    value: DOCTOR,
    label: 'Doctor',
  },
  {
    value: PATIENT,
    label: 'Patient',
  },
];
