<template>
  <div id="app">
    <div class="style-general" v-if="ready">
      <router-view />
    </div>
    <div v-else-if="error" class="is-flex is-justify-content-center is-align-items-center"
    style="height: 100vh">
      <div class="has-text-centered">
         <b-icon
              icon="alert-circle"
              size="is-large"
              type="is-danger">
          </b-icon>
          <p>{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import getCurrentUser from '@/services/firebase';
import { ADMIN } from '@/config/user';
import { get } from '@/api/setting';

export default {
  name: 'App',
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.appName}`,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.about },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'consultancy, consult, appointment, communication, business' },
        { name: 'og:type', content: 'website' },
        { name: 'og:description', content: this.about },
        { name: 'og:site_name', content: this.appName },
        { name: 'og:image', content: this.url('/img/icon.jpeg') },
        { name: 'twitter:title', content: this.appName },
        { name: 'twitter:description', content: this.about },
        { name: 'twitter:image:src', content: this.url('/img/icon.jpeg') },
      ],
    };
  },
  data() {
    return {
      loading: true,
      admin: ADMIN,
      error: null,
      setupFree: [
        'Login', 'Setup',
      ],
    };
  },
  computed: {
    ...mapGetters({
      ready: 'appReady',
      userProfile: 'profil',
      settings: 'settings',
    }),
    appName() {
      return this.settings && this.settings.organization
        ? this.settings.organization.name : process.env.VUE_APP_NAME;
    },
    about() {
      if (this.settings && this.settings.organization && this.settings.organization.about) {
        return this.settings.organization.about;
      }
      return 'Konsult for connecting consultant and clients';
    },
  },
  methods: {
    ...mapActions(['getStatus']),
  },
  mounted() {
    const loadingComponent = this.$buefy.loading.open();
    getCurrentUser().then(user => {
      this.$store.commit('SET_AUTH', user);
      // get system status
      return this.getStatus();
    }).then(() => get()).then(response => {
      const { data } = response;
      // if setup is completed, proceed and set appReady to true.
      if (data && data.setup && data.setup.completed) {
        this.$store.commit('SETTINGS', {
          organization: {
            ...data.organization,
            phone: data.africaTalking.phoneNumber,
          },
          setup: data.setup,
        });
        this.$store.commit('SET_APP_STATE', true);
      } else if (this.userProfile && this.userProfile === this.admin) {
        this.$router.push({ name: 'Setup' });
        this.$store.commit('SET_APP_STATE', true);
      } else if (this.setupFree.includes(this.$route.name)) {
        this.$store.commit('SET_APP_STATE', true);
      } else {
        this.error = 'Setup is not completed for the app';
      }
    })
      .catch((e) => {
        this.error = e;
      })
      .finally(() => {
        loadingComponent.close();
      });
  },
};
</script>
<style>
</style>
