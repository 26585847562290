import Vue from 'vue';
import VueRouter from 'vue-router';
import { ADMIN } from '@/config/user';
import store from '../store';

Vue.use(VueRouter);

const forAdmin = (to, from, next) => {
  if (store.getters.profil === ADMIN) next();
  else next({ name: 'Home' });
};

const routes = [
  {
    path: '/',
    component: () => import('../views/dashboard/index.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/dashboard/Home.vue'),
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import('../views/dashboard/Users.vue'),
        beforeEnter: forAdmin,
      },
      {
        path: 'create-user',
        name: 'CreateUser',
        component: () => import('../views/dashboard/CreateUser.vue'),
        beforeEnter: forAdmin,
      },
      {
        path: 'appointment',
        component: () => import('../views/dashboard/appointment/Index.vue'),
        children: [
          {
            path: '/',
            name: 'Appointment',
            component: () => import('../views/dashboard/appointment/List.vue'),
          },
          {
            path: 'view/:id',
            name: 'ViewAppointment',
            component: () => import('../views/dashboard/appointment/Show.vue'),
          },
          {
            path: 'create',
            name: 'CreateAppointment',
            component: () => import('../views/dashboard/appointment/Create.vue'),
          },
          {
            path: 'admin-create',
            name: 'CreateAppointmentAdmin',
            component: () => import('../views/dashboard/appointment/AdminCreate.vue'),
          },
          {
            path: 'edit/:appointmentId',
            name: 'EditAppointment',
            component: () => import('../views/dashboard/appointment/Create.vue'),
          },
          {
            path: 'admin-edit/:appointmentId',
            name: 'EditAppointmentAdmin',
            component: () => import('../views/dashboard/appointment/AdminCreate.vue'),
          },
        ],
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('../views/dashboard/Notifications.vue'),
      },
      {
        path: 'help',
        name: 'Help',
        component: () => import('../views/dashboard/Help.vue'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('../views/dashboard/Profile.vue'),
      },
      {
        path: '/settings/:tab?',
        name: 'Settings',
        component: () => import('../views/settings/Index.vue'),
      },
      {
        path: 'inbound-calls',
        name: 'InboundCalls',
        component: () => import('../views/dashboard/InboundCalls.vue'),
      },
    ],
    beforeEnter: (to, from, next) => {
      if (store.getters.user) next();
      else next({ name: 'Login' });
    },
  },
  {
    path: '/setup/:tab?',
    name: 'Setup',
    component: () => import('../views/Setup.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters.auth) next({ name: 'Login' });
      else next();
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/create-account',
    name: 'CreateAccount',
    component: () => import('../views/CreateAccount.vue'),
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import('../views/VerifyEmail.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.email) {
        if (store.getters.user) {
          next({ name: 'Home' });
        } else next();
      } else next({ name: 'Login' });
    },
  },
  {
    path: '/verify-user-account',
    name: 'VerifyUserAccount',
    component: () => import('../views/VerifyUserAccount.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.email) {
        if (!store.getters.userId) next({ name: 'Login' });
        else next();
      } else next({ name: 'Login' });
    },
  },
  {
    path: '/verify-appointment/:appointmentId',
    name: 'VerifyAppointment',
    component: () => import('../views/VerifyAppointment.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.email) {
        if (!store.getters.userId) next({ name: 'Login' });
        else next();
      } else next({ name: 'Login' });
    },
  },

  // Public booking of appointment
  {
    path: '/book-appointment',
    name: 'BookAppointment',
    component: () => import('../views/BookAppointment.vue'),
    beforeEnter: (to, from, next) => {
      // if already logged in and it is not embedded
      if (store.getters.user && !to.query.embed) next({ name: 'CreateAppointment' });
      else next();
    },
  },
  {
    path: '/validate-email',
    name: 'ValidateEmail',
    component: () => import('../views/ValidateEmail.vue'),
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
// collapse the nav menu afer navigation in small screen
router.afterEach(() => {
  if (window.screen.width <= 768 && store.getters.nav) store.commit('SET_NAV', false);
});

export default router;
