/*eslint-disable */
import { auth } from '@/pluging/firebase';

export default function getCurrentUser() {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
      resolve(user);
    });
  });
}
